import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

// environment
import { environment } from '@environment';

// services
import { OrderByService } from '@services/order-by.service';
import { CanonicalTagService } from 'app/canonical-tag.service';
import { RandomTimeService } from '@services/random-time.service';
import { OffersRestApiService } from '@services/offers-rest-api.service';
import { ContentPlaceholderService } from '@services/content-placeholder.service';
import { OffersByDepartmentService } from '@services/offers-by-department.service';

// models
import { OffersAPI } from '@models/offers';
import { PlaceholderType } from '@models/placeholder';
import { OrderByDefinition, OrderByField, OrderByName, OrderByUrl, OrderPipeConfig } from '@models/orderBy';
import { httpError } from '@models/httpError';

// pipes
import { OrderPipe } from 'ngx-order-pipe';

// date-fns
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

@Component({
  selector: 'app-offers-list',
  templateUrl: './offers-list.component.html',
  styleUrls: ['./offers-list.component.css']
})
export class OffersListComponent implements OnInit, AfterViewInit {

  noResults: boolean = false;
  httpErrorCode: number;
  httpErrorMessage: string;
  Offers: OffersAPI[] = [];
  referLink: string = environment.referLink;
  orderByCriteria: OrderByField;
  showOfferListContent:boolean = false;
  showOfferListTopContent:boolean = false;
  
  orderByPipeConfig: OrderPipeConfig;
  tempMasonryItems: OffersAPI[] = [];
  currentMasonryItems: OffersAPI[] = [];
  // used to improve UX speed when all 'offer' items are displayed
  applyHiddenStyleOfferListContent: boolean = false;
  
  // seo variables
  homePageTitle:string = `Ofertas del Día ${this.getCurrentDate()}  ✅ Promociones y Descuentos en tiempo real ⏲`;
  currentPageTitle: string;
  currentPageDescription: string;

  // scrolling
  @ViewChild('scrollableDiv') scrollableDiv!: ElementRef;
  scrollStarted: boolean = true;
  scrollEnded: boolean = false;

  constructor(
    private meta: Meta,
    private title: Title,
    private router: Router,
    private orderPipe: OrderPipe,
    private route: ActivatedRoute,
    private orderByService: OrderByService,
    private offersRestApi: OffersRestApiService,
    private randomTimeService: RandomTimeService,
    private canonicalTagService: CanonicalTagService,
    private contentPlaceHolderService: ContentPlaceholderService,
    private offersByDepartmentService: OffersByDepartmentService
   ) { 
    }

  ngOnInit() {
    this.setTitle();
    this.setCanonicalTag();
    this.setMetaTagDescription();
    this.setMetaOpenGraphProperties();
    this.initOrderBy();
    this.offerList();
  }

  ngAfterViewInit(): void {
    this.scrollableDiv?.nativeElement?.addEventListener('scroll', this.onScroll.bind(this));
  }

  // code from chatgpt https://chatgpt.com/share/b3fa1acd-cfea-45ad-ac32-21456573e044
  onScrollEnded(event: Event): void {
    const target = event.target as HTMLElement;
    const scrollLeft = target.scrollLeft;
    const scrollWidth = target.scrollWidth;
    const clientWidth = target.clientWidth;

    if (scrollLeft === 0) {
      console.log('Reached the start of the scroll');
      this.scrollStarted = true;
    } else if (scrollLeft + clientWidth >= scrollWidth) {
      console.log('Reached the end of the scroll');
      this.scrollEnded = true;
    }
  }

  onScroll(): void {
    this.scrollStarted = false;
    this.scrollEnded = false;
  }

  setCanonicalTag() {
    const currentRouteUrl = this.router.url;
    this.canonicalTagService.setCanonicalURL();
  }

  /**
   * Set Meta Tags
   */
  setMetaTagDescription() {
    const currentDescription = 'Publicamos las mejores 🥇 Ofertas, Promociones y Descuentos en tiempo real de Amazon México. ✅ ¡Entra y consíguelos ya!';
    this.meta.updateTag(
      {
        name: 'description',
        content: currentDescription
      }
    );  
    this.currentPageDescription = currentDescription;
  }

  setMetaOpenGraphProperties() {
    // docs https://ogp.me/#optional
    this.meta.updateTag(
      {
        property: 'og:locale',
        content: 'es-MX',
      }
    );

    // docs https://ogp.me/#types
    this.meta.updateTag(
      {
        property: 'og:type',
        content: 'website',
      }
    );

    // docs https://ogp.me/#metadata
    this.meta.updateTag(
      {
        property: 'og:title',
        content: this.homePageTitle,
      }
    );

    // docs https://ogp.me/#structured
    this.meta.updateTag(
      {
        property: 'og:image',
        content: 'https://res.cloudinary.com/xadani-mexico/image/upload/v1712947614/assets/ofertas-del-dia/icon-512x512.png',
      }
    );

    // docs https://ogp.me/#metadata
    this.meta.updateTag(
      {
        property: 'og:url',
        content: this.router.url,
      }
    );
  }

  setTitle() {
    const currentTitle = this.homePageTitle;
    this.title.setTitle(currentTitle);
    this.currentPageTitle = currentTitle;
  }

  /**
   * Get a list of Offers of the Day.
   * @autor Carlos Alonso Casales Ortega <calonso011@yahoo.com.mx>
   * @return [Offers]
   */
  offerList() {

    this.offersRestApi.getOffersList().subscribe({
      //next
      next: data => {        
        if (data.offersList) {
          this.currentMasonryItems = data.offersList;
          this.showOfferListTopContent = true;
          this.showOfferListContent = true;
        }else{
          this.noResults = true;
        }
      },
      //error
      error: (err: httpError) => {
        this.httpErrorCode = err.httpStatusCode;
        this.httpErrorMessage = err.httpErrorMessage;
      },
      //complete
      complete: () => {
        console.info("Data correctly recieved by Observer ✔️");
      }
    });
  }

  /**
   * Set config for use into ngx order pipe.
   * @autor Carlos Alonso Casales Ortega <calonso011@yahoo.com.mx>
   */
  setCurrentOrderByCriteria( el: OrderByDefinition ){

    this.applyHiddenStyleOfferListContent = true;
    this.contentPlaceHolderService.setIsLoading(PlaceholderType.partial);       

    setTimeout(() => {
      this.showOfferListContent = false;
      // apply new order by config
      this.orderByPipeConfig = el.config;
      this.renderOrderBy();

      setTimeout(() => {
        this.applyHiddenStyleOfferListContent = false;
        this.showOfferListContent = true;
        this.contentPlaceHolderService.setLoaded(PlaceholderType.partial);
      }, this.randomTimeService.getRandomLongTimeForPlaceholder());
    }, 103);
  }

  renderOrderBy() {
    this.tempMasonryItems = this.applyOrderBy(this.currentMasonryItems);
    this.currentMasonryItems = [];

    setTimeout(() => {
      this.currentMasonryItems = this.tempMasonryItems;
    }, 303);
  }

  applyOrderBy( currentItems:OffersAPI[] ):OffersAPI[]{
    return this.orderPipe.transform(currentItems, this.orderByPipeConfig.expression, this.orderByPipeConfig.reverse, this.orderByPipeConfig.caseInsensitive, this.orderByPipeConfig.comparator);
  }

  initOrderBy() {
    this.route.params.subscribe(params => {
      let curentOrderByName: OrderByName = OrderByName.lastest;
      switch (true) {
        // "high discount" case
        case params['order-by'] === OrderByUrl.price:
          curentOrderByName = OrderByName.highDiscount;
          this.orderByPipeConfig = this.orderByService.getOrderByOption(curentOrderByName).config;
          this.orderByService.setCurrentOrderBy(this.orderByService.getOrderByOption(curentOrderByName));
        break
          
        case params['order-by'] === OrderByUrl.lastest:
          curentOrderByName = OrderByName.lastest;
          this.orderByPipeConfig = this.orderByService.getOrderByOption(curentOrderByName).config;
          this.orderByService.setCurrentOrderBy(this.orderByService.getOrderByOption(curentOrderByName));
        break;
      
        default:
          this.orderByPipeConfig = this.orderByService.getOrderByOption(curentOrderByName).config;
          this.orderByService.setCurrentOrderBy(this.orderByService.getOrderByOption(curentOrderByName));
        break;
      }
    });    
  }

  moveScrollNext() {
    this.offersByDepartmentService.moveScrollNext();
  }

  moveScrollBack() {
    this.offersByDepartmentService.moveScrollBack();
  }

  getCurrentDate() {
    return format(new Date(), "d 'de' MMMM 'de' y", { locale: es });
  }
}